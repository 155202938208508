<script>
// see https://docs.google.com/spreadsheets/d/1wYN59IQ-J5ANG5y2WbyLnHkRBBpaunZXL3x5R36zkBk/edit?usp=sharing
import imgLeftEye from '@/v-shop/assets/left.png'
import imgRightEye from '@/v-shop/assets/right.png'
import imgRightCrystal from '@/v-shop/assets/cristal-ojo-derecho.png'
import imgLeftCrystal from '@/v-shop/assets/cristal-ojo-izquierdo.png'
import { getGraduationSequence, formatter } from '@/utils'

export default {
	props: ['ProductPage'],
	inject: ['hookContainer'],
	hookZone: ['before--actions'],
	showHook(hookZone, ProductPage) {
		return ProductPage.product.info.customizationKey?.indexOf('graduationSelector') === 0
	},
	data() {
		return {
			rightImg: imgRightEye,
			leftImg: imgLeftEye,
			imgRightCrystal,
			imgLeftCrystal,
			eyes: ['right', 'left'],
		}
	},
	created() {
		this.hookContainer().customization.key = this.customizationKey
	},
	methods: {
		customizationIs(type) {
			const search = 'graduationSelector' + type
			return this.customizationKey.toLowerCase().indexOf(search.toLowerCase()) === 0
		},
		onUpdate(field, $event) {
			if (!this.hookContainer().customization?.data) {
				this.hookContainer().customization.data = {}
			}
			this.hookContainer().customization.data[field] = $event.text || $event
		},
	},
	computed: {
		graduationsSimple() {
			const extractNumber = (grad) =>
				Number(grad.replace('Grad_', '').replace('n', '-').replace('p', '+').replace('d', '.'))

			const formatObject = (s) => ({ text: formatter.format(s), value: s })

			return Object.entries(this.cData).reduce((acc, [k, v]) => {
				if (k.includes('Grad_') && v) {
					acc.push(formatObject(extractNumber(k)))
				}
				return acc
			}, [])
		},
		graduationsIntermediate() {
			return [...getGraduationSequence(0, -9), ...getGraduationSequence(0.25, 4)]
		},
		graduationsComplex() {
			return this.cData.graduations
		},
		hasError() {
			return Object.keys(this.hookContainer().validation).length
		},
		customizationKey() {
			return this.ProductPage.product.info.customizationKey
		},
		cData() {
			return this.ProductPage.product.info.customizationData
		},
		collectionMessages() {
			return this.$srv('CollectionMessage', [])
				.filter((c) => this.ProductPage.product.collectionsIds.includes(c.collectionId))
				.flatMap((x) => x.importantInfoList)
		},
		importantInfoList() {
			if (!this.$srv('CollectionMessage')?.length && this.cData.showImportantInfo)
				return this.cData.importantInfoList
			let res = []
			if (this.cData.showImportantInfo) res = this.cData.importantInfoList
			return [...res, ...this.collectionMessages]
		},
	},
}
</script>

<template>
	<div :class="$bem('graduation-selector')">
		<div v-if="importantInfoList">
			<div
				:style="{ borderLeft: `5px solid ${cData.color}` }"
				:class="$bem('__important-info')"
				class="pa-1 my-2 font-2"
				v-for="(info, n) in importantInfoList"
				:key="n"
			>
				<div class="my-1">
					<v-icon :color="info.color" :large="$b.d">{{ info.icon }}</v-icon>
					<span :style="{ color: info.color }" class="pa-1 rounded">{{ info.title }}</span>
					<span> {{ info.description }}</span>
				</div>
			</div>
		</div>
		<div
			:class="$bem('__description', 'mb-4')"
			v-if="!!cData.description"
			v-html="cData.description.html"
		></div>
		<v-row justify="center" :class="$bem('__container')">
			<template v-if="customizationIs('complex') && cData.graduations">
				<v-col cols="12" md="6" sm="6" v-for="eye in eyes" :key="eye">
					<GraduationSelectorComplex
						:rawGraduations="graduationsComplex"
						:excludedCylinders="[-2.75, -2.5, -2, -1.5, -1]"
						:class="$bem('__selector')"
						:img="eye === 'right' ? rightImg : leftImg"
						:label="eye === 'right' ? 'derecho' : 'izquierdo'"
						@update:graduation="onUpdate(`${eye}EyeGraduation`, $event)"
						@update:cylinder="onUpdate(`${eye}EyeCylinder`, $event)"
						@update:axis="onUpdate(`${eye}EyeAxis`, $event)"
					/>
				</v-col>
			</template>
			<template v-else-if="customizationIs('intermediate')">
				<v-col cols="12" md="6" sm="6" v-for="eye in eyes" :key="eye">
					<GraduationSelectorIntermediate
						:rawGraduations="graduationsIntermediate"
						:img="eye === 'right' ? rightImg : leftImg"
						:label="eye === 'right' ? 'derecho' : 'izquierdo'"
						:class="$bem('__selector')"
						@update:graduation="onUpdate(`${eye}EyeGraduation`, $event)"
						@update:cylinder="onUpdate(`${eye}EyeCylinder`, $event)"
						@update:axis="onUpdate(`${eye}EyeAxis`, $event)"
					/>
				</v-col>
			</template>

			<template v-else-if="customizationIs('simple')">
				<v-col cols="12" md="6" sm="6" v-for="eye in eyes" :key="eye">
					<GraduationSelectorSimple
						:graduationRange="graduationsSimple"
						:img="eye === 'right' ? rightImg : leftImg"
						:label="eye === 'right' ? 'derecho' : 'izquierdo'"
						:class="$bem('__selector')"
						@update:graduation="onUpdate(`${eye}EyeGraduation`, $event)"
					/>
				</v-col>
			</template>

			<template v-else-if="customizationIs('crystal') && graduationsComplex">
				<v-col cols="12" md="6" sm="6" v-for="eye in eyes" :key="eye">
					<GraduationSelectorComplex
						:rawGraduations="graduationsComplex"
						:class="$bem('__selector')"
						:img="eye === 'right' ? imgRightCrystal : imgLeftCrystal"
						:label="eye === 'right' ? 'derecho' : 'izquierdo'"
						@update:graduation="onUpdate(`${eye}EyeGraduation`, $event)"
						@update:cylinder="onUpdate(`${eye}EyeCylinder`, $event)"
						@update:axis="onUpdate(`${eye}EyeAxis`, $event)"
					/>
				</v-col>
			</template>

			<template v-else-if="customizationIs('multifocal')">
				<v-col cols="12" md="6" sm="6" v-for="eye in eyes" :key="eye">
					<GraduationSelectorMultifocal
						:graduationRange="graduationsSimple"
						:img="eye === 'right' ? rightImg : leftImg"
						:label="eye === 'right' ? 'derecho' : 'izquierdo'"
						:class="$bem('__selector')"
						@update:graduation="onUpdate(`${eye}EyeGraduation`, $event)"
						@update:add="onUpdate(`${eye}EyeAdd`, $event)"
					/>
				</v-col>
			</template>
		</v-row>
		<div :class="$bem('__actions', 'mx-auto')">
			<ProductActions
				:product="ProductPage.product"
				:variant="ProductPage.selectedVariant"
				:customization="ProductPage.customization"
				:ctaText="ProductPage.buttonText"
				@validation="hookContainer().validation = $event"
				class="py-3"
			/>
			<transition name="fade">
				<div v-if="hasError" class="d-flex justify-center py-2 text-center">
					<div :class="$bem('__error')" class="pa-2 font-2 error rounded">
						Seleccione todas las graduaciones para continuar con la compra
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.graduation-selector {
	&__important-info {
		border: 1px solid #eee;
	}

	&__description {
	}

	&__selector {
		border: 1px solid var(--primary);
	}

	&__actions {
		max-width: 600px;
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
</style>

